<template>
    <div class="h-100 reset-password-wrapper d-flex">
      <!-- Left Section: Logo and App Title -->
      <div class="p-3 mb-xl-0 left-section d-flex flex-column justify-content-center align-items-center text-center">
        <div v-if="appInfo && appInfo.image_file_name" class="mb-3">
          <img :src="`/storage/LOGOS/${appInfo.image_file_name}`" alt="App Logo" class="Loginlogo" />
        </div>
        <h1 class="loginTitle fw-bold">MARKETPLACE</h1>
        <h2 class="text-uppercase"v-if="appInfo">{{ appInfo.title }}</h2>
      </div>
  
      <!-- Right Section: Forms -->
      <div class="right-section d-flex flex-column justify-content-center align-items-center mb-5 mb-xl-0">
        <div class="form-container">
          <!-- Step 1 -->
          <form v-if="!showStep2" @submit.prevent="sendVerificationToken" class="row g-1 p-3 p-md-2">
            <div class="col-12 text-center mb-1 mb-lg-5">
              <h3 class="fs-1 text-center fw-bold">ΞΕΧΑΣΑΤΕ ΤΟΝ ΚΩΔΙΚΟ ΣΑΣ;</h3>

              <span>Εισαγάγετε τη διεύθυνση email που χρησιμοποιήσατε κατά την εγγραφή σας και θα σας στείλουμε οδηγίες για να επαναφέρετε τον κωδικό πρόσβασής σας.</span>
            </div>
            <div class="col-12 mb-2">
              <label class="form-label">Email address</label>
              <input type="email" class="form-control form-control-md" v-model="email" placeholder="name@example.com">
            </div>
            <div class="col-12 text-center mt-4">
              <button type="submit" class="btn bg-primary fw-bold text-white btn-lg btn-block btn-light lift text-uppercase btn-submit" :disabled="loading">
                Αποστολη
              </button><br>
              <div class="col-12 text-center mt-4">
            <div class="button-group d-flex align-items-center justify-content-center">
              <a href="/merchant" class="btn mt-3 fs-5" style="color: grey;">Σύνδεση</a>
              <!-- <button @click="goToLogin" class="btn mt-3 fs-5" style="color: grey;">Σύνδεση</button> -->
              <span class="mt-2">|</span>
              <button @click="goToRegisterBusinessPartner" class="btn mt-3  fs-5" style="color: grey;">Εγγραφή</button>
            </div>
          </div>

            </div>
            <div v-if="errorMessage" class="error-div text-center mt-3">
              <p>{{ errorMessage }}</p>
            </div>
            <div v-if="successMessage" class="success-message text-center mt-3">
              <p>{{ successMessage }}</p>
            </div>
          </form>
  
          <!-- Step 2 -->
          <form v-if="showStep2" @submit.prevent="completePasswordChange" class="row g-1 p-3 p-md-2">
            <div class="col-12 text-center mb-1 mb-lg-5">
              <h1>Αλλαγή Κωδικού πρόσβασης</h1>
              <span>Εισάγετε τον νέο σας κωδικό.</span>
            </div>
            <div class="col-12 mb-2 position-relative">
              <label class="form-label">Νέος κωδικός</label>
              <input :type="showpassword? 'text' : 'password'" class="form-control form-control-md" style="letter-spacing: 1.5px;" v-model="password" placeholder="********">
              <button class="btn position-absolute fs-6 color-700 eyeIcon" type="button" @click="togglePasswordVisibility('new')">
                <i :class="showpassword ? 'icofont-eye-blocked' : 'icofont-eye-alt'"></i>
              </button>
            </div>
            <div class="col-12 mb-2 position-relative">
              <label class="form-label">Επιβεβαίωση νέου κωδικού</label>
              <input :type="showpasswordConfirmation ? 'text' : 'password'" class="form-control form-control-md" style="letter-spacing: 1.5px;" v-model="passwordConfirmation" placeholder="********">
              <button class="btn position-absolute fs-6 color-700 eyeIcon" type="button"
                @click="togglePasswordVisibility('confirm')">
                <i :class="showpasswordConfirmation ? 'icofont-eye-blocked' : 'icofont-eye-alt'"></i>
              </button>
            </div>
            <div class="col-12 text-center mt-4">
              <button type="submit" class="btn fw-bold px-4 py-2 bg-primary text-white btn-submit" :disabled="loading">
                Αλλαγή
              </button><br>
              <div class="col-12 text-center mt-4">
  <div class="button-group d-flex align-items-center justify-content-center">
    <button @click="goToLogin" class="btn mt-3 fs-5" style="color: grey;">Σύνδεση</button>
    <span class="mt-2">|</span>
    <button @click="goToRegisterBusinessPartner" class="btn mt-3 fs-5" style="color: grey;">Εγγραφή</button>
  </div>
</div>
            </div>
            <div v-if="errorMessage" class="error-div text-center mt-3">
              <p>{{ errorMessage }}</p>
            </div>
            <div v-if="successMessage" class="success-message text-center mt-3">
              <p>{{ successMessage }}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import apiClient from '@/services/api';
  import { getAppInfo } from '@/services/appInfo.js';
  import { useRouter } from 'vue-router';

  
  export default {
    name: 'ResetPassword',
    methods: {
    togglePasswordVisibility(field) {
        // Toggle the visibility for the corresponding field
        if (field === 'new') {
          this.showpassword = !this.showpassword;
        } else if (field === 'confirm') {
          this.showpasswordConfirmation = !this.showpasswordConfirmation;
        }
      }
    },
    data() {
      return {
        password: '',
        passwordConfirmation: '',
        showpassword: false,
        showpasswordConfirmation: false,
      };
    },
    setup() {
    const router = useRouter();
      const email = ref('');
      const password = ref('');
      const passwordConfirmation = ref('');
      const token = ref('');
      const loading = ref(false);
      const errorMessage = ref('');
      const successMessage = ref('');
      const showStep2 = ref(false);
      const appInfo = ref(null);
  
      onMounted(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const emailParam = urlParams.get('email');
        const tokenParam = urlParams.get('token');
        try {
        const info = await getAppInfo();
        appInfo.value = info.data.success ? info.data.data : false;
      } catch (e) {
        errorMessage.value = 'Failed to load app info';
      }
        
        if (emailParam && tokenParam) {
          email.value = emailParam;
          token.value = tokenParam;
          showStep2.value = true;
        }
      });
  
      const sendVerificationToken = async () => {
        loading.value = true;
        errorMessage.value = '';
        successMessage.value = '';
        try {
          const response = await apiClient.post('send-verification-token', { email: email.value });
          if (response.data.success) {
            successMessage.value = response.data.message;
          } else {
            errorMessage.value = response.data.message;
          }
        } catch (error) {
          errorMessage.value = 'An error occurred while sending the verification token.';
        } finally {
          loading.value = false;
        }
      };
  
      const completePasswordChange = async () => {
        loading.value = true;
        errorMessage.value = '';
        successMessage.value = '';
        try {
          const response = await apiClient.post('complete-password-change', {
            email: email.value,
            token: token.value,
            password: password.value,
            password_confirmation: passwordConfirmation.value,
          });
          if (response.data.success) {
            successMessage.value = response.data.message;
            setTimeout(() => {
              window.location.href = '/merchant';
            }, 2000);
          } else {
            errorMessage.value = response.data.message;
          }
        } catch (error) {
          errorMessage.value = 'An error occurred while changing the password.';
        } finally {
          loading.value = false;
        }
      };

      const goToRegisterBusinessPartner = () => {
      router.push('/business-partner-request'); // Using router.push
    };

    

    const goToLogin = () => {
      router.push('/login');
    };

  
      return {
        email,
        password,
        passwordConfirmation,
        loading,
        errorMessage,
        successMessage,
        showStep2,
        sendVerificationToken,
        completePasswordChange,
        appInfo,
        goToRegisterBusinessPartner,
        goToLogin,
      };
    },
  };
  </script>
  
  <style scoped>
  .button-group {
    display: flex;
    align-items: center;
    /* Vertically centers items within the flex container */
    justify-content: center;
    /* Centers items horizontally within the flex container */
  }

  .button-group span {
    margin: 0 10px;
    /* Adjust spacing around the separator */
    font-size: 1.2rem;
    /* Adjust font size to match buttons if needed */
  }

  /* Main Wrapper to hold the left and right sections */
  .reset-password-wrapper {
    width: 100vw;
    /* Full viewport width */

    height: 100vh !important;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .Loginlogo {
    height: 200px;
  }

  /* Left Section: App info, occupies half the screen */
  .left-section {
    background-color: rgb(240, 240, 240);
    width: 50%;
    /* Takes up half the screen */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /* Right Section: Forms, occupies the other half */
  .right-section {
    background-color: white;
    width: 50%;
    /* Takes up half the screen */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  /* The form container */
  .form-container {
    width: 100%;
    max-width: 450px;
  }

  /* Input fields styling */
  .form-control-md {
    width: 100%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  /* Error and success message styling */
  .error-div {
    color: red;
    text-align: center;
  }

  .loginTitle {
    text-shadow: 2px 0px;
  }

  .success-message {
    color: rgb(64, 168, 64);
    text-align: center;
  }

  .eyeIcon{
    top: 30px;
    right: 10px;
  }

  /* Responsive behavior for smaller screens */
  @media (max-width: 768px) {
    .reset-password-wrapper {
      flex-direction: column;
      height: unset !important;
      justify-content: start;
    }

    .Loginlogo {
      height: 160px;
    }

    .left-section,
    .right-section {
      width: 100%;
      /* Stack the sections vertically on smaller screens */
    }
  }
</style>
  
